import { Component, OnInit, OnDestroy } from "@angular/core";
import { AppTranslationService, EntityService } from "./core/services";
import "app/core/extensions/stringExtensions";
import {
    Language,
    QrmCellFull,
    VisualStyle,
} from "./core/models/entity-models";
import { ProjectText } from "./core/models/base-entity";
import { takeUntil, filter } from "rxjs/operators";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import "app/core/extensions/arrayExtensions";
import "app/core/extensions/dateExtensions";
import { Unsubscriber } from "./core/component-base/models";
import { NavigationItem } from "./layout/types/fuse-navigation";
import { FuseNavigation } from "../@fuse/types";
import { AuthenticationService } from "./core/authentication/authentication.service";
import { Permission } from "./core/models";
import { navigation } from "./navigation/navigation";
import { environment } from "../environments/environment";
import { Title } from "@angular/platform-browser";
import Quill from "quill";
import { SignalRService } from "./core/services/signalR-service/signalR-service";
const parchment = Quill.import("parchment");
const block = parchment.query("block");
block.tagName = "p";
// or class NewBlock extends Block {} NewBlock.tagName = 'DIV'
Quill.register(block /* or NewBlock */, true);

var bold = Quill.import("formats/bold");
bold.tagName = "b"; // Quill uses <strong> by default
Quill.register(bold, true);

var italic = Quill.import("formats/italic");
italic.tagName = "i";
Quill.register(italic, true);

const Inline = Quill.import("blots/inline");

class CustomColor extends Inline {
    [x: string]: any;
    static blotName: string;
    static tagName: string;
    constructor(domNode, value) {
        super(domNode, value);

        // Map <font> properties
        domNode.style.color = domNode.color;

        var spanContent = new Inline(Inline.create());
        const span = this.replaceWith(spanContent);

        span.children.forEach((child) => {
            if (child.attributes) child.attributes.copy(span);
            if (child.unwrap) child.unwrap();
        });

        this.remove();

        return span;
    }
}

CustomColor.blotName = "customColor";
CustomColor.tagName = "FONT";

Quill.register(CustomColor, true);

class GrammarlyInline extends Inline {
    static blotName: string;
    static tagName: string;
    static className: string;
    constructor(domNode, value) {
        super(domNode, value);
    }
}
GrammarlyInline.tagName = "G";
GrammarlyInline.blotName = "grammarly-inline";
GrammarlyInline.className = "gr_";
Quill.register(GrammarlyInline);

@Component({
    selector: "app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent extends Unsubscriber implements OnInit, OnDestroy {
    private navigation: FuseNavigation[];

    constructor(
        private readonly fuseNavigationService: FuseNavigationService,
        private readonly authenticationService: AuthenticationService,
        private readonly titleService: Title,

        private signalRService: SignalRService
    ) {
        super();

        this.authenticationService.credentials
            .pipe(takeUntil(this.isUnsubscribing))
            .subscribe((_) => {
                this.setUserNav();
            });

        this.setDefaultNav();
    }

    public ngOnInit(): void {
        this.titleService.setTitle(environment.appTitle);
    }

    private setCurrentNavigation() {
        const nav: FuseNavigation[] =
            this.fuseNavigationService.getCurrentNavigation();

        nav.forEach((navItem) => {
            if (!navItem.children) this.verifyNavItem(navItem);
            else {
                navItem.children.forEach((navItemChildren) => {
                    this.verifyNavItem(navItemChildren);
                });
            }
        });
    }

    private verifyNavItem(navItem: FuseNavigation) {
        const permisssions: Permission[] =
            this.authenticationService.permissions;
        if (
            permisssions.some((_) => _.functionID === navItem.id) &&
            !permisssions.find((_) => _.functionID === navItem.id).view
        )
            this.fuseNavigationService.removeNavigationItem(navItem.id);
    }
    private setUserNav() {
        if (this.authenticationService.currentUser) {
            const originalNav =
                this.authenticationService.currentUser.user.navigation;
            this.navigation = JSON.parse(JSON.stringify(originalNav));
            this.fuseNavigationService.unregister("main");
            this.fuseNavigationService.register("main", this.navigation);

            // Set the main navigation as our current navigation
            this.fuseNavigationService.setCurrentNavigation("main");
        }
    }

    private setDefaultNav() {
        // Get default navigation
        const originalNav = navigation;
        this.navigation = JSON.parse(JSON.stringify(originalNav));
        // Register the navigation to the service
        this.fuseNavigationService.unregister("main");
        this.fuseNavigationService.register("main", this.navigation);

        // Set the main navigation as our current navigation
        this.fuseNavigationService.setCurrentNavigation("main");
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.fuseNavigationService.unregister("main");
    }
}
